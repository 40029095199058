import React from 'react';
import portfolioImage1 from '../assets/portfolio1.jpg';
import portfolioImage2 from '../assets/portfolio2.jpg';
import portfolioImage3 from '../assets/portfolio3.jpg';
import portfolioImage4 from '../assets/portfolio4.jpg';
import portfolioImage5 from '../assets/portfolio5.jpg';
import portfolioImage6 from '../assets/portfolio6.jpg';

export default function Portfolio() {
  const portfolioItems = [
    { image: portfolioImage1, title: "Projekt 1" },
    { image: portfolioImage2, title: "Projekt 2" },
    { image: portfolioImage3, title: "Projekt 3" },
    { image: portfolioImage4, title: "Projekt 4" },
    { image: portfolioImage5, title: "Projekt 5" },
    { image: portfolioImage6, title: "Projekt 6" },
  ];

  return (
    <div className="container mx-auto py-16 px-6">
      <h2 className="text-4xl font-bold text-center mb-12">Portfolio</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        {portfolioItems.map((item, index) => (
          <div
            key={index}
            className="relative shadow-lg rounded-lg overflow-hidden group transition-transform transform hover:scale-105 duration-300"
          >
            <img
              src={item.image}
              alt={item.title}
              className="w-full h-56 object-cover transition-opacity duration-300 group-hover:opacity-75"
            />
            <div
              className="absolute inset-0 bg-black bg-opacity-40 flex justify-center items-center opacity-0 transition-opacity duration-300 group-hover:opacity-100"
            >
              <h3 className="text-white text-xl font-bold">{item.title}</h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
