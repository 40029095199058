// src/pages/Datenschutz.js
import React from 'react';

function Datenschutz() {
  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-4">Datenschutzerklärung</h1>
      <p>
        Wir freuen uns sehr über Ihr Interesse an unserem Unternehmen. Datenschutz hat einen besonders hohen Stellenwert für die Geschäftsleitung der [Unternehmensname]. Eine Nutzung der Internetseiten der [Unternehmensname] ist grundsätzlich ohne jede Angabe personenbezogener Daten möglich. Sofern eine betroffene Person besondere Services unseres Unternehmens über unsere Internetseite in Anspruch nehmen möchte, könnte jedoch eine Verarbeitung personenbezogener Daten erforderlich werden.
      </p>
      <p>
        Die Verarbeitung personenbezogener Daten, beispielsweise des Namens, der Anschrift, E-Mail-Adresse oder Telefonnummer einer betroffenen Person, erfolgt stets im Einklang mit der Datenschutz-Grundverordnung und in Übereinstimmung mit den für die [Unternehmensname] geltenden landesspezifischen Datenschutzbestimmungen.
      </p>
      <p>
        <strong>1. Name und Anschrift des Verantwortlichen</strong><br />
        Verantwortlicher im Sinne der Datenschutz-Grundverordnung, sonstiger in den Mitgliedstaaten der Europäischen Union geltenden Datenschutzgesetze und anderer Bestimmungen mit datenschutzrechtlichem Charakter ist die:
      </p>
      <p>
        Max Mustermann<br />
        Musterstraße 1<br />
        12345 Musterstadt<br />
        Deutschland<br />
        Tel.: +49 (0) 123 456789<br />
        E-Mail: info@mustermann.de<br />
      </p>
      <p>
        <strong>2. Erfassung von allgemeinen Daten und Informationen</strong><br />
        Die Internetseite der [Unternehmensname] erfasst mit jedem Aufruf der Internetseite durch eine betroffene Person oder ein automatisiertes System eine Reihe von allgemeinen Daten und Informationen.
      </p>
    </div>
  );
}

export default Datenschutz;
