import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DarkModeToggle from './DarkModeToggle';

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-white dark:bg-gray-800 shadow-md">
      <div className="container mx-auto flex items-center justify-between p-4">
        <Link to="/" className="text-2xl font-bold text-gray-900 dark:text-white">
          Drohnenfotografie
        </Link>
        <div className="hidden md:flex items-center space-x-4">
          <Link to="/" className="text-gray-900 dark:text-gray-100 hover:text-gray-500 dark:hover:text-gray-400">Home</Link>
          <Link to="/about" className="text-gray-900 dark:text-gray-100 hover:text-gray-500 dark:hover:text-gray-400">Über uns</Link>
          <Link to="/services" className="text-gray-900 dark:text-gray-100 hover:text-gray-500 dark:hover:text-gray-400">Dienstleistungen</Link>
          <Link to="/portfolio" className="text-gray-900 dark:text-gray-100 hover:text-gray-500 dark:hover:text-gray-400">Portfolio</Link>
          <Link to="/contact" className="text-gray-900 dark:text-gray-100 hover:text-gray-500 dark:hover:text-gray-400">Kontakt</Link>
          <DarkModeToggle />
        </div>
        <button 
          onClick={toggleMenu} 
          className="md:hidden flex items-center px-3 py-2 border rounded text-gray-900 dark:text-gray-100 border-gray-400 dark:border-gray-600"
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
          </svg>
        </button>
      </div>
      <div className={`md:hidden ${isOpen ? 'block' : 'hidden'} bg-white dark:bg-gray-800`}>
        <div className="flex flex-col items-center space-y-4 p-4">
          <Link to="/" className="text-gray-900 dark:text-gray-100 hover:text-gray-500 dark:hover:text-gray-400" onClick={() => setIsOpen(false)}>Home</Link>
          <Link to="/about" className="text-gray-900 dark:text-gray-100 hover:text-gray-500 dark:hover:text-gray-400" onClick={() => setIsOpen(false)}>Über uns</Link>
          <Link to="/services" className="text-gray-900 dark:text-gray-100 hover:text-gray-500 dark:hover:text-gray-400" onClick={() => setIsOpen(false)}>Dienstleistungen</Link>
          <Link to="/portfolio" className="text-gray-900 dark:text-gray-100 hover:text-gray-500 dark:hover:text-gray-400" onClick={() => setIsOpen(false)}>Portfolio</Link>
          <Link to="/contact" className="text-gray-900 dark:text-gray-100 hover:text-gray-500 dark:hover:text-gray-400" onClick={() => setIsOpen(false)}>Kontakt</Link>
          <DarkModeToggle />
        </div>
      </div>
    </nav>
  );
}
