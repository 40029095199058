import heroImage from '../assets/hero.jpg';
import { Link } from 'react-router-dom';

export default function Home() {
  return (
    <div className="relative h-screen bg-cover bg-center" style={{ backgroundImage: `url(${heroImage})` }}>
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="relative flex items-center justify-center h-full">
        <div className="text-center text-white">
          <h1 className="text-4xl font-bold mb-4">Drohnenfotografie Heidenheim</h1>
          <p className="text-xl mb-8">Atemberaubende Luftaufnahmen für jede Gelegenheit</p>
          <Link to="/portfolio" className="px-6 py-3 bg-blue-500 hover:bg-blue-600 rounded-full text-lg font-semibold">Unser Portfolio</Link>
        </div>
      </div>
    </div>
  );
}
