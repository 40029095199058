// src/pages/Impressum.js
import React from 'react';

function Impressum() {
  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-4">Impressum</h1>
      <p>
        <strong>Angaben gemäß § 5 TMG</strong>
      </p>
      <p>
        Max Mustermann<br />
        Musterstraße 1<br />
        12345 Musterstadt<br />
      </p>
      <p>
        <strong>Kontakt</strong><br />
        Telefon: +49 (0) 123 456789<br />
        E-Mail: info@mustermann.de<br />
      </p>
      <p>
        <strong>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV</strong><br />
        Max Mustermann<br />
        Musterstraße 1<br />
        12345 Musterstadt<br />
      </p>
      <p>
        <strong>Haftungsausschluss</strong><br />
        Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen.
      </p>
    </div>
  );
}

export default Impressum;
