// src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="bg-gray-800 text-gray-300 p-6">
      <div className="container mx-auto flex justify-between">
        <div>
          <p>&copy; 2024 Drohnenfotografie - Alle Rechte vorbehalten.</p>
        </div>
        <div className="flex space-x-4">
          <Link to="/impressum" className="hover:underline">Impressum</Link>
          <Link to="/datenschutz" className="hover:underline">Datenschutz</Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
