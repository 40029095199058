import aboutImage from '../assets/about.jpg';

export default function About() {
  return (
    <div className="container mx-auto py-16 px-6">
      <h2 className="text-4xl font-bold text-center mb-8">Über uns</h2>
      <div className="flex flex-col md:flex-row items-center">
        <div className="md:w-1/2 md:pr-8">
          <img src={aboutImage} alt="Über uns" className="w-full rounded-lg shadow-lg"/>
        </div>
        <div className="md:w-1/2 mt-8 md:mt-0">
          <p className="text-lg mb-4">
            Wir sind ein Team von leidenschaftlichen Drohnenfotografen, die es sich zur Aufgabe gemacht haben, einzigartige und
            atemberaubende Luftaufnahmen zu erstellen. Unsere Expertise liegt in der Erfassung von beeindruckenden Bildern aus
            neuen Perspektiven, die die Welt aus einer ganz anderen Sicht zeigen.
          </p>
          <p className="text-lg">
            Mit jahrelanger Erfahrung und modernster Ausrüstung garantieren wir Ihnen qualitativ hochwertige Bilder, die Sie begeistern
            werden. Ob für persönliche Projekte oder geschäftliche Zwecke, wir sind bereit, Ihre Visionen in die Tat umzusetzen.
          </p>
        </div>
      </div>
    </div>
  );
}
