import React from 'react';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';

export default function Contact() {
  return (
    <div className="container mx-auto py-16 px-6">
      <h2 className="text-4xl font-bold text-center mb-8">Kontaktieren Sie uns</h2>
      <div className="max-w-3xl mx-auto">
        <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg space-y-6">
          {/* Address */}
          <div className="flex items-center space-x-4">
            <FaMapMarkerAlt className="text-blue-500 text-2xl" />
            <div>
              <h3 className="text-xl font-semibold text-gray-700 dark:text-gray-300">Adresse</h3>
              <p className="text-gray-600 dark:text-gray-400">Musterstraße 123, 12345 Musterstadt</p>
            </div>
          </div>

          {/* Phone */}
          <div className="flex items-center space-x-4">
            <FaPhoneAlt className="text-blue-500 text-2xl" />
            <div>
              <h3 className="text-xl font-semibold text-gray-700 dark:text-gray-300">Telefon</h3>
              <p className="text-gray-600 dark:text-gray-400">+49 123 456789</p>
            </div>
          </div>

          {/* Email */}
          <div className="flex items-center space-x-4">
            <FaEnvelope className="text-blue-500 text-2xl" />
            <div>
              <h3 className="text-xl font-semibold text-gray-700 dark:text-gray-300">E-Mail</h3>
              <p className="text-gray-600 dark:text-gray-400">info@musterfirma.de</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
