import serviceImage1 from '../assets/service1.jpg';
import serviceImage2 from '../assets/service2.jpg';
import serviceImage3 from '../assets/service3.jpg';

export default function Services() {
  const services = [
    {
      title: "Luftaufnahmen für Immobilien",
      description: "Präsentieren Sie Ihre Immobilien mit beeindruckenden Luftbildern, die Ihre Objekte von ihrer besten Seite zeigen.",
      image: serviceImage1,
    },
    {
      title: "Event-Fotografie",
      description: "Halten Sie die wichtigsten Momente Ihrer Veranstaltungen aus der Vogelperspektive fest.",
      image: serviceImage2,
    },
    {
      title: "Landschaftsaufnahmen",
      description: "Erleben Sie die Schönheit der Natur aus atemberaubenden Perspektiven mit unseren hochwertigen Landschaftsaufnahmen.",
      image: serviceImage3,
    },
  ];

  return (
    <div className="container mx-auto py-16 px-6">
      <h2 className="text-4xl font-bold text-center mb-8">Unsere Dienstleistungen</h2>
      <div className="grid md:grid-cols-3 gap-8">
        {services.map((service, index) => (
          <div key={index} className="shadow-lg rounded-lg overflow-hidden">
            <img src={service.image} alt={service.title} className="w-full h-56 object-cover"/>
            <div className="p-6">
              <h3 className="text-2xl font-bold mb-4">{service.title}</h3>
              <p className="text-lg">{service.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
